<template>
  <div class="w-100 position-relative" style="overflow-y: auto">
    <CCard class="pdf-wrapper">
      <div class="modal-body modal-container">
        <div class="pdf-wrapper">
          <div class="pdf-page fs-16">
            <!--            <button class="c-sidebar-close c-class-toggler close">-->
            <!--              <i class="c-icon cil-x"></i> Quay lại-->
            <!--            </button>-->
            <div id="pdf1" class="pdf-content ml-3 mr-3">
              <div class="text-center fs-16">
                <strong class="bold">Mẫu C.III.2</strong><br>
                <strong>Văn bản thông báo kế hoạch tổ chức hoạt động xúc tiến</strong><br>
                <strong>hỗn hợp đầu tư, thương mại, du lịch, ngoại giao kinh tế</strong><br>
                <p class="font-italic">(Khoản 2, Điều 95 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021)</p>
                <hr>
              </div>
              <div class="row fs-16">
                <div class="col-md-6">
                  <CInput placeholder="TÊN CƠ QUAN CHỦ TRÌ" horizontal :value.sync="detailItem.tenCoQuanChuTri"/>
                  <CInput placeholder="Số:" horizontal :value.sync="detailItem.soVanBan"/>
                </div>
                <div class="col-md-6">
                  <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt Nam</p>
                  <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                  <p class="font-weight font-italic" style="float: right; display: flex">
                    <CInput placeholder="Địa điểm:" horizontal :value.sync="detailItem.diaDiem"/>
                    <span class="mt-2">, ngày {{ getDay(detailItem.ngayVanBan) }} tháng {{ getMonth(detailItem.ngayVanBan)}} năm {{ getYear(detailItem.ngayVanBan)}}</span>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4" style="float: left">
                  <p class="font-weight">V/v thông báo kế hoạch tổ chức
                    hoạt động xúc tiến hỗn hợp đầu tư, thương
                    mại, du lịch, ngoại giao kinh tế</p>
                </div>
              </div>
              <div class="text-center">
                <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
              </div>
              <div class="ml-3">
                <p><span class="pl-3">Căn cứ quy</span> định tại Khoản 2, Điều 95 Nghị định số 31/2021/NĐ-CP ngày
                  26/3/2021 quy định chi tiết và hướng
                  dẫn thi hành một số điều của Luật Đầu tư, (Tên cơ quan chủ trì xin thông báo kế hoạch tổ chức các hoạt
                  động xúc
                  tiến hỗn hợp thương mại, du lịch, ngoại giao kinh tế có nội dung liên quan đến xúc tiến đầu tư như
                  sau:</p>
              </div>
              <div class="row">
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>1. Tên hoạt động xúc tiến đầu tư:</label>
                  </div>
                  <div class="col-8">
                    <CInput placeholder="Nhập tên hoạt động đầu tư" horizontal :value.sync="detailItem.tenHoatDong"/>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>2. Thời gian thực hiện:</label>
                  </div>
                  <div class="col-4">
                    <v-date-picker class="" v-model="detailItem.thoiGianThucHien" locale="vi">
                      <template v-slot="{ inputValue, inputEvents }">
                        <CInput
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="Chọn ngày"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">3. Địa điểm (trong nước/nước ngoài):</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Trong nước:</li>
                        <li class="mt-4">Nước ngoài:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <!--                      <select  class="form-group" v-model="item.diaDiem_TrongNuoc">-->
                      <!--                        <option v-for="it in optionsTinhThanh" :value="it.value" v-bind:key="it.value">{{it.label}}</option>-->
                      <!--                      </select>-->
                      <CSelect class="form-group" placeholder="Chọn tỉnh thành" :options="optionsTinhThanh" horizontal :value.sync="detailItem.diaDiem_TrongNuoc"/>
                      <CSelect class="form-group" placeholder="Chọn quốc gia" :options="optionsQuocGia" horizontal :value.sync="detailItem.diaDiem_NuocNgoai"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>4. Thành phần dự kiến tham gia:</label>
                  </div>
                  <div class="col-8">
                    <CInput class="form-group" placeholder="Nhập các thành phần dự kiến tham gia" horizontal
                            :value.sync="detailItem.thanhPhanDuKienThamGia"/>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>5. Quy mô hoạt động:</label>
                  </div>
                  <div class="col-8">
                    <CInput class="form-group" placeholder="Nhập quy mô hoạt động" horizontal :value.sync="detailItem.quyMoHoatDong"/>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">6. Lĩnh vực/Địa bàn kêu gọi đầu tư:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Ngành/Lĩnh vực kêu gọi đầu tư:</li>
                        <li class="mt-4">Địa bàn kêu gọi đầu tư:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <!--                      <select :value.sync="item.nganhLinhVucKeuGoiDauTuId" class="form-group">-->
                      <!--                        <option v-for="it in optionsNganhLinhVuc" :value="it.value" v-bind:key="it.value">{{it.label}}</option>-->
                      <!--                      </select>-->
                      <CSelect class="form-group" placeholder="Chọn ngành/lĩnh vực" horizontal :options="optionsNganhLinhVuc"
                               :value.sync="detailItem.nganhLinhVucKeuGoiDauTuId"/>
                      <CSelect class="form-group" placeholder="Chọn địa bàn" horizontal :options="optionsTinhThanh" :value.sync="detailItem.diaBanKeuGoiDauTu"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>7. Các hoạt động chính:
                    <span class="font-italic">(bao gồm danh mục giấy chứng nhận đăng ký đầu tư/chấp thuận chủ trương đầu
                      tư thỏa thuận dự kiến trao/ký kết</span>
                  </label>
                  <div class="col-12">
                    <CInput class="form-group" placeholder="Nhập các hoạt động chính" horizontal :value.sync="detailItem.cacHoatDongChinh"/>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">8. Cơ quan, tổ chức phối hợp thực hiện:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Tên tổ chức/cơ quan trong nước:</li>
                        <li class="mt-4">Tên tổ chức/cơ quan nước ngoài:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <CInput class="form-group" placeholder="Chọn tên tổ chức/cơ quan trong nước" horizontal
                              :value.sync="detailItem.toChucCoQuan_TrongNuoc"/>
                      <CInput class="form-group" placeholder="Chọn tên tổ chức/cơ quan nước ngoài" horizontal
                              :value.sync="detailItem.toChucCoQuan_NuocNgoai"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>9. Chương trình dự kiến:</label>
                  <div class="col-12">
                    <CInput class="form-group" placeholder="Nhập chương trình dự kiến" horizontal :value.sync="detailItem.chuongTrinhDuKien"/>
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>10. Phương án tổ chức thực hiện:</label>
                  <div class="col-12">
                    <CInput class="form-group" placeholder="Nhập phương án tổ chức thực hiện" horizontal
                            :value.sync="detailItem.phuongAnToChucThucHien"/>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">11. Kinh phí:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Ngân sách:</li>
                        <li class="mt-4">Nguồn xã hội hóa:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <CInput class="form-group" placeholder="Nhập kinh phí nguồn ngân sách" horizontal :value.sync="detailItem.kinhPhi_NganSach"/>
                      <CInput class="form-group" placeholder="Nhập kinh phí nguồn xã hội hóa" horizontal :value.sync="detailItem.kinhPhi_XaHoiHoa"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">12. Thông tin liên hệ:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-3">
                      <ul>
                        <li>Họ tên:</li>
                        <li class="mt-4">Email:</li>
                      </ul>
                    </div>
                    <div class="col-3">
                      <CInput class="form-group" placeholder="Nhập họ tên" horizontal :value.sync="detailItem.lienHe_HoTen"/>
                      <CInput class="form-group" placeholder="Nhập email" horizontal :value.sync="detailItem.lienHe_Email"/>
                    </div>
                    <div class="col-3">
                      <ul>
                        <li class="">Số điện thoại:</li>
                      </ul>
                    </div>
                    <div class="col-3">
                      <CInput class="form-group" placeholder="Nhập số điện thoại" horizontal :value.sync="detailItem.lienHe_SoDienThoai"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <p class="ml-3">Đề nghị Bộ Kế hoạch và Đầu tư xem xét, thống nhất để {{detailItem.tenCoQuanChuTri}} có cơ sở triển khai tổ chức thực hiện... /.</p>
                </div>
                <div class="col-xl-12 row">
                  <div class="col">
                    <div class="float-left">
                      <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>
                      <span class="font-weight">- Như trên;</span> <br>
                      <span class="font-weight">- Lưu: VT, ...</span> <br>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <div class="float-right mr-5">
                    <span class="font-weight-bold text-uppercase">Thủ trưởng cơ quan chủ trì</span> <br>
                    <span class="font-weight font-italic">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span>
                  </div>
                  <br>
                  <br>
                </div>
              </div>
              <div class="form-group row mb-0 mt-3">
                <div class="col-12">
                  <b class="mt-5">Đính kèm file</b>
                  <FileUpload ref="upload" @onChange="changeFiles" :type="type" :attachments="attachments"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CCardFooter tag="div" class="text-right">
          <CButton color="primary" @click="saveItem" :disabled="isSaving">Lưu lại</CButton>
          <CButton color="danger" class="ml-2"  @click="saveItem2" :disabled="isSaving">Nộp văn bản</CButton>
          <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
          <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
        </CCardFooter>
      </div>
    </CCard>
  </div></template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import {
  GET_THONGBAOKEHOACH,
  UPDATE_THONGBAOKEHOACH
} from '@/store/modules/VanBanTBKHTCHDXTHHDTTMDLNGKT/actionTypes'
import moment from 'moment'
import FileUpload from '@/components/file-upload/FileUpload'

export default {
  name: 'ThongBaoKeHoachUpdate',
  components: {
    FileUpload
  },
  data () {
    return {
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: [],
      type: 'VanBanTBKHTCHDXTHHDTTMDLNGKT',
      attachments: [],
      Attachments: []
    }
  },
  computed: {
    ...mapGetters('thongBaoKeHoach', {
      isSaving: 'isSaving',
      responseResult: 'responseResult',
      detailItem: 'detailItem'
    })
  },
  methods: {
    ...mapActions('thongBaoKeHoach', {
      getItem: GET_THONGBAOKEHOACH,
      updateItem: UPDATE_THONGBAOKEHOACH
    }),
    async saveItem () {
      this.detailItem.trangThai = 1
      this.detailItem.dinhKem = JSON.stringify(this.Attachments)
      await this.updateItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/thong-bao-ke-hoach-xt-hon-hop' })
      }
    },
    async saveItem2 () {
      this.detailItem.trangThai = 2
      this.detailItem.dinhKem = JSON.stringify(this.Attachments)
      await this.updateItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        await this.$router.push({ path: '/thong-bao-ke-hoach-xt-hon-hop' })
      }
    },
    print () {
      localStorage.setItem('thong_bao_ke_hoach', JSON.stringify(this.detailItem))
      // this.$router.push({ path: `/thong-bao-ke-hoach-xt-hon-hop/${this.item.id}/bieu-in` })
      const routeData = this.$router.resolve({ path: '/thong-bao-ke-hoach-xt-hon-hop/bieu-in' })
      window.open(routeData.href, '_blank')
    },
    cancel () {
      this.$router.push({ path: '/thong-bao-ke-hoach-xt-hon-hop' })
    },
    changeFiles (data) {
      // this.item = {
      //   ...this.item,
      //   ListFileAttachAdd: data.ListFileAttachAdd,
      //   listValueFileAttachRemove: data.listValueFileAttachRemove
      // }
      // console.log(data)
      this.Attachments = data.ListFileAttachAdd
    },
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    await this.getItem(this.$route.params.id)
    this.attachments = JSON.parse(this.detailItem.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
  }
}
</script>
